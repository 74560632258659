import React from "react";
import styled from "styled-components";

const ServiceItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 275px;
    margin: 0.5rem;
    background: linear-gradient(135deg, #f8f9fa 30%, #e9ecef 100%);
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    text-align: center;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
`;

const ServiceTitle = styled.h3`
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #343a40;
    text-align: center;
`;

const ServiceDescription = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0.5rem 0;
    color: #6c757d;
    text-align: center;
`;

const ServiceDescriptionItem = styled.li`
    font-size: 1rem;
    margin: 0.5rem 0;
    color: #495057;
`;

const ServicePrice = styled.p`
    font-size: 1.2rem;
    font-weight: bold;
    margin: 1rem 0 0;
    color: #495057;
    text-align: center;
    margin-top: auto;
`;

const ServiceItem = ({title, services, price}) => {
    return (
        <ServiceItemWrapper>
            <ServiceTitle>{title}</ServiceTitle>
            <ServiceDescription>
                {services.map((service, index) => (
                    <ServiceDescriptionItem key={index}>{service}</ServiceDescriptionItem>
                ))}
            </ServiceDescription>
            <ServicePrice>{price}</ServicePrice>
        </ServiceItemWrapper>
    );
};

export default ServiceItem;
