import React from 'react';
import ServiceItem from './ServiceItem';
import styled from 'styled-components';
import DentalHygieneInfo from "./DentalHygieneInfo";
import ThalassoInfo from "./ThalassoInfo";
import PricingInfo from "./PricingInfo";
import FAQInfo from "./FAQInfo";

const Container = styled.div`
    padding: 2rem;
    max-width: 1200px;
    margin: auto;
`;

const ServiceCategory = styled.div`
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ServiceItemsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    padding: 1rem 0;
    
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const CategoryTitle = styled.h2`
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    color: #333;
    text-align: center;
`;

const Subtitle = styled.p`
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #666;
    text-align: center;
`;

const ServiceList = () => (
    <Container>
        <hr/>
        <PricingInfo/>
        <hr/>
        <ServiceCategory>
            <CategoryTitle>Kleine Hunde (bis 29 cm)</CategoryTitle>
            <Subtitle>z.B. Maltipoo, Bolonka, Malteser, Havaneser, Shi Tzu, Yorkshire Terrier, Chihuahua</Subtitle>
            <ServiceItemsContainer>
                <ServiceItem
                    title="Waschen, Föhnen"
                    services={["Zweimal Shampoonieren", "Fachgerechtes Föhnen", "je 30 Minuten"]}
                    price="25 Euro"
                />
                <ServiceItem
                    title="Premium Fellpflege"
                    services={["Zweimal Shampoonieren", "Fachgerechtes Föhnen", "Ausbürsten mit Schnitt", "Inkl. Pfotenpflege- und Ohrenreinigung"]}
                    price="60 Euro"
                />
                <ServiceItem
                    title="Professionelles Trimmen"
                    services={["Bürsten und Kämmen", "Trimmen und Schneiden", "Pfotenpflege und Ohrenreinigung"]}
                    price="65 Euro"
                />
            </ServiceItemsContainer>
        </ServiceCategory>
        <ServiceCategory>
            <CategoryTitle>Mittlere Hunde (ab 30 cm)</CategoryTitle>
            <Subtitle>z.B. Cocker Spaniel, Kleinpudel, Französiche Bulldogge, Zwergschnauzer, Cairn Terrier</Subtitle>
            <ServiceItemsContainer>
                <ServiceItem
                    title="Waschen, Föhnen"
                    services={["Zweimal Shampoonieren", "Fachgerechtes Föhnen", "je 30 Minuten"]}
                    price="25 Euro"
                />
                <ServiceItem
                    title="Premium Fellpflege"
                    services={["Zweimal Shampoonieren", "Fachgerechtes Föhnen", "Ausbürsten mit Schnitt", "Inkl. Pfotenpflege- und Ohrenreinigung"]}
                    price="75 Euro"
                />
                <ServiceItem
                    title="Professionelles Trimmen"
                    services={["Bürsten und Kämmen", "Trimmen und Schneiden", "Pfotenpflege und Ohrenreinigung"]}
                    price="80 Euro"
                />
            </ServiceItemsContainer>
        </ServiceCategory>
        <ServiceCategory>
            <CategoryTitle>Große Hunde (ab 50 cm)</CategoryTitle>
            <Subtitle>z.B. Bearded Collie, Australien Shepherd, Border Collie, Fox Terrier, Golden- und
                Labradoodel, Golden Retriever, Irish Terrier, Tibet Terrier, Mittelschnauzer</Subtitle>
            <ServiceItemsContainer>
                <ServiceItem
                    title="Waschen, Föhnen"
                    services={["Zweimal Shampoonieren", "Fachgerechtes Föhnen", "je 30 Minuten"]}
                    price="25 Euro"
                />
                <ServiceItem
                    title="Premium Fellpflege"
                    services={["Zweimal Shampoonieren", "Fachgerechtes Föhnen", "Ausbürsten mit Schnitt", "Inkl. Pfotenpflege- und Ohrenreinigung"]}
                    price="105 Euro"
                />
                <ServiceItem
                    title="Professionelles Trimmen"
                    services={["Bürsten und Kämmen", "Trimmen und Schneiden", "Pfotenpflege und Ohrenreinigung"]}
                    price="105 Euro"
                />
            </ServiceItemsContainer>
        </ServiceCategory>
        <ServiceCategory>
            <CategoryTitle>Extra große Hunde (ab 60 cm)</CategoryTitle>
            <Subtitle>z.B. Riesenschnauzer, Neufundländer, Irischer Wolfshund, Berner Sennenhund,
                Großpudel</Subtitle>
            <ServiceItemsContainer>
                <ServiceItem
                    title="Waschen, Föhnen"
                    services={["Zweimal Shampoonieren", "Fachgerechtes Föhnen", "je 30 Minuten"]}
                    price="25 Euro"
                />
                <ServiceItem
                    title="Premium Fellpflege"
                    services={["Zweimal Shampoonieren", "Fachgerechtes Föhnen", "Ausbürsten mit Schnitt", "Inkl. Pfotenpflege- und Ohrenreinigung"]}
                    price="125 Euro"
                />
                <ServiceItem
                    title="Professionelles Trimmen"
                    services={["Bürsten und Kämmen", "Trimmen und Schneiden", "Pfotenpflege und Ohrenreinigung"]}
                    price="130 Euro"
                />
            </ServiceItemsContainer>
        </ServiceCategory>
        <ServiceCategory>
            <CategoryTitle>Kurztermine</CategoryTitle>
            <Subtitle>
                Unsere Preise sind nicht pro angefangene Stunde zu verstehen, denn wir wissen dass jedes Tier anders ist.
                Daher halten wir die Uhr im Blick und berechnen den Endpreis fair im 15 Minuten Takt.
            </Subtitle>
            <ServiceItemsContainer>
                <ServiceItem
                    title="Kurztermin"
                    services={["zb. Krallen schneiden", "Ohrhaar-Entfernung", "Pfotenpflege", "Freischneiden der Augen"]}
                    price="15 Euro je angefangene 15 Minuten"
                />
            </ServiceItemsContainer>
        </ServiceCategory>
        <ServiceCategory>
            <CategoryTitle>Ultraschall Zahnreinigung</CategoryTitle>
            <DentalHygieneInfo/>
            <ServiceItemsContainer>
                <ServiceItem
                    title="Zahnbürste"
                    services={["Anschaffung der Zahnbürste", "Einlagerung und Verwendung für Ihren Hund"]}
                    price="8 Euro"
                />
                <ServiceItem
                    title="Zahnreinigung"
                    services={["Professionelle Ultraschall Zahnreinigung"]}
                    price="18 Euro"
                />
            </ServiceItemsContainer>
        </ServiceCategory>
        <ServiceCategory>
            <CategoryTitle>Hundemassagen</CategoryTitle>
            <Subtitle>Eine Hundemassage kann zur Entspannung und Linderung von Muskelschmerzen und –
                Spannungen beitragen. Sie fördert die Durchblutung und verbessert das allgemeine
                Wohlbefinden des Hundes</Subtitle>
            <ServiceItemsContainer>
                <ServiceItem
                    title="Hundemassage"
                    services={["Entspannende Massage für Ihren Hund"]}
                    price="Ab 20 Euro"
                />
            </ServiceItemsContainer>
        </ServiceCategory>
        <ServiceCategory>
            <CategoryTitle>Thalasso-Therapie</CategoryTitle>
            <ThalassoInfo/>
            <ServiceItemsContainer>
                <ServiceItem
                    title="Thalasso-Therapie - Kleiner Hund"
                    services={["Vorbehandlung des Hundes", "Auftragen des Meerschlammes inkl. Ausgedehnter Massage", "Auswaschen des Schlammes", "Abschließendes Föhnen und trocknen"]}
                    price="Ab 64 Euro"
                />
                <ServiceItem
                    title="Thalasso-Therapie - Mittlerer Hund"
                    services={["Vorbehandlung des Hundes", "Auftragen des Meerschlammes inkl. Ausgedehnter Massage", "Auswaschen des Schlammes", "Abschließendes Föhnen und trocknen"]}
                    price="Ab 74 Euro"
                />
                <ServiceItem
                    title="Thalasso-Therapie - Großer Hund"
                    services={["Vorbehandlung des Hundes", "Auftragen des Meerschlammes inkl. Ausgedehnter Massage", "Auswaschen des Schlammes", "Abschließendes Föhnen und trocknen"]}
                    price="Ab 84 Euro"
                />
            </ServiceItemsContainer>
        </ServiceCategory>
        <ServiceCategory>
            <CategoryTitle>Welpeneingewöhnung</CategoryTitle>
            <Subtitle>Die Welpeneingewöhnung ist ein wichtiger Schritt für die Entwicklung des Welpen
                und die Beziehung zwischen Hund und Halter. In der Welpeneingewöhnung wird der
                Welpe an die Pflege und das Verhalten im Hundesalon gewöhnt.
            </Subtitle>
            <ServiceItemsContainer>
                <ServiceItem
                    title="Welpeneingewöhnung"
                    services={["3 Trainingseinheiten für Welpen und Junghunde", "inkl. einmalig Baden und Föhnen", "Dauer: ca. 10-15 Minuten"]}
                    price="Ab 50 Euro"
                />
            </ServiceItemsContainer>
        </ServiceCategory>
        <ServiceCategory>
            <CategoryTitle>Entfilzung</CategoryTitle>
            <Subtitle>Ich entfilze Hunde nur im Rahmen einer Premiumpflege. In seltenen Fällen mache ich hin
                und wieder eine Ausnahme, dann aber nur zum Wohl Ihres Tieres.</Subtitle>
        </ServiceCategory>
        <hr/>
        <Subtitle>
            Jeder Hund ist so individuell, wie die Leistungen, die für ihn zu erbringen sind. Aus diesem
            Grund berechne ich meine Leistungen nach tatsächlichem Arbeits- bzw. Zeitaufwand.
            Dabei gehen wir vom günstigsten Fall aus.
        </Subtitle>
        <hr/>
        <FAQInfo/>
    </Container>
);

export default ServiceList;
